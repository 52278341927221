import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";

import type { IKeycloakUserInfo } from "../types/auth";
import { useGetEnv } from "./useEnv";
import { keycloak, logout } from "../utils/services";
import { logger } from "../utils/helpers";
import { LOCALSTORE_KEY_AUTH } from "../utils/constants";

export function useAuth() {
  const env = useGetEnv();

  const query = useQuery({
    queryKey: ["keycloak-auth"],
    queryFn: async () => {
      if (!env?.sso_enabled) return null;
      const initKc = await initKeycloak();
      if (initKc) return await getUserInfo();
      return null;
    },
    enabled: !!env,
    cacheTime: Infinity,
    staleTime: 0,
    refetchOnWindowFocus: true,
  });

  async function initKeycloak() {
    try {
      await keycloak.init({ onLoad: "check-sso" });
      return true;
    } catch (error) {
      logger("useAuth: keycloak init error");
      return false;
    }
  }

  async function getUserInfo() {
    try {
      const isLoggedIn = !!JSON.parse(
        localStorage.getItem(LOCALSTORE_KEY_AUTH) ?? "0"
      );
      if (!isLoggedIn) return null;
      return (await keycloak.loadUserInfo()) as IKeycloakUserInfo;
    } catch (error) {
      logger("useAuth: keycloak loadUserInfo error");
      return null;
    }
  }

  return query;
}

export function useLogout() {
  const env = useGetEnv();

  useEffect(() => {
    logout(env?.corp_site_url);
  }, [env?.corp_site_url]);
}
