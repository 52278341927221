import TagManager from "react-gtm-module";

import type { IIndex } from "../types/generic";
import { getEnvData } from "./services";

export async function sendGAEvent(
  eventName: string,
  parameters: Record<string, unknown> = {}
) {
  const { env } = await getEnvData();
  if (env === "prod") {
    TagManager.dataLayer({
      dataLayer: { ...parameters, event: eventName },
    });
  }
}

export function deepClone<T>(data: T) {
  if (data === null || typeof data !== "object") return data;

  if (typeof global.structuredClone !== "undefined") {
    return structuredClone(data);
  }

  const result = (Array.isArray(data) ? [] : {}) as T;
  Object.keys(data).forEach((k) => {
    const key = k as keyof T;
    result[key] = deepClone(data[key]);
  });

  return result;
}

export function translateText(messages: Record<string, any>) {
  return (key: string, ...args: any[]) => {
    if (!messages[key]) {
      return key;
    }
    let text = messages[key];

    for (let i = 0; i < args.length; i++) {
      const re = new RegExp(`\\{${i}\\}`, "g");
      text = text.replace(re, args[i]);
    }
    return text;
  };
}

export const cookie = (function () {
  const getCookie = (name: string) => {
    const splitted = `; ${document.cookie}`.split(`; ${name}=`);
    if (splitted.length === 2) {
      return splitted.pop()?.split(";").shift()?.trim();
    }
  };

  const setCookie = ({
    name,
    value,
    options = {},
  }: {
    name: string;
    value: string;
    options?: IIndex;
  }) => {
    const opts = { path: "/", ...options };
    document.cookie = Object.entries(opts).reduce(
      (result, [key, keyVal]) => (result += `; ${key}=${keyVal}`),
      `${name}=${value}`
    );
  };

  return {
    get: getCookie,
    set: setCookie,
  };
})();

export const logger = (function () {
  if (process.env.NODE_ENV === "development") {
    return console.log;
  } else {
    return (...args: any[]) => undefined;
  }
})();
