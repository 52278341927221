import { HTTPError, createHttpError, httpErrorHandler } from "./errorHandler";

const request = (function () {
  async function parseResponse<T>(res: Response) {
    const responseText = await res.text();
    try {
      return JSON.parse(responseText) as T;
    } catch (error) {
      return responseText as unknown as T;
    }
  }

  return {
    /**
     * Makes Fetch request, resolving with a promise or throwing an HTTPError
     *
     * @param url url to initiate the request to
     * @param options request options
     * @returns Promise of response
     */
    make: async <T>(url: string, options: RequestInit = {}) => {
      try {
        const res = await fetch(url, { method: "GET", ...options });

        if (!res.ok) {
          throw httpErrorHandler(res);
        } else {
          return parseResponse<T>(res);
        }
      } catch (e) {
        if (e instanceof HTTPError) {
          throw e;
        } else {
          const error = createHttpError(e as Error);
          throw error;
        }
      }
    },
    /**
     * Makes Fetch calls without parsing the response - throws Error if error occurs
     *  - use when network response is not desired
     *
     * @param url url to make the call to
     * @returns Promise of void
     */
    call: async (url: string, options: RequestInit = {}) => {
      const res = await fetch(url, options);
      if (!res.ok) throw new Error();
    },
  };
})();

export default request;
