import { Helmet, HelmetProvider } from "react-helmet-async";
import { useRouteError } from "react-router-dom";

function ErrorBoundary() {
  const error: any = useRouteError();

  function handleReload() {
    window.location.reload();
  }

  return (
    <HelmetProvider>
      <Helmet titleTemplate="Oneworld Accuracy%s">
        <title> | {`${error.status || "Error"}`}</title>
      </Helmet>
      <div className="error-container">
        <p>Sorry, something went wrong</p>
        <button className="link-login" onClick={handleReload}>
          Refresh page
        </button>
      </div>
    </HelmetProvider>
  );
}

export default ErrorBoundary;
