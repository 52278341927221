import { useQuery } from "@tanstack/react-query";

import type { IContentNotification } from "../types/content";
import { useLocale } from "./useLocale";
import { useGetEnv } from "./useEnv";
import request from "../utils/request";

export function useGetHomeNotifications() {
  const env = useGetEnv();
  const [localeName] = useLocale();

  type DataType = Pick<IContentNotification, "id" | "date" | "type"> & {
    content_versions: Pick<
      IContentNotification["content_versions"][0],
      "translations"
    >[];
  };

  const query = useQuery({
    queryKey: ["notifications", { show_on_homepage: true }],
    queryFn: async () => {
      return await request.make<{ data: DataType[] }>(
        encodeURI(
          `${env?.oasys_url}/api/v1/corp/items/notifications` +
            `?fields=id,date,type` +
            `,content_versions.translations.id` +
            `,content_versions.translations.heading` +
            `,content_versions.translations.friendlyLabel` +
            `,content_versions.translations.message` +
            `,content_versions.translations.notifications_versions_id` +
            `,content_versions.translations.languages_code` +
            `&filter={"show_on_homepage":true}` +
            `&sort=date`
        )
      );
    },
    enabled: !!env,
  });

  const localizedData = (() => {
    return query.data?.data?.map((prop) => ({
      ...prop,
      content_versions: (() => {
        const translations = prop.content_versions[0].translations;
        return (
          translations.find((t) => t.languages_code === localeName) ||
          translations.find(
            (t) => t.languages_code === process.env.REACT_APP_DEFAULT_LOCALE
          ) ||
          translations[0] ||
          {}
        );
      })(),
    }));
  })();

  return { ...query, data: localizedData };
}
