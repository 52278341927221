import { useState } from "react";
import { useNavigate } from "react-router-dom";
import InputAdornment from "@mui/material/InputAdornment";
import TextField, { TextFieldProps } from "@mui/material/TextField";

import { useGetInterface } from "../../hooks/useInterface";

type Props = Omit<TextFieldProps, "onChange">;

function SearchProduct(props: Props) {
  const [query, setQuery] = useState("");
  const navigate = useNavigate();
  const interfaceItems = useGetInterface();

  function handleSubmit(ev: React.FormEvent<HTMLElement>) {
    ev.preventDefault();
    navigate(`/products?f=${query}`);
  }

  return (
    <form onSubmit={handleSubmit} autoComplete="off" noValidate>
      <TextField
        placeholder={interfaceItems?.data?.productKeyword}
        type="search"
        autoComplete="off"
        spellCheck="false"
        variant="standard"
        InputLabelProps={{ shrink: true }}
        {...props}
        InputProps={{
          disableUnderline: true,
          endAdornment: (
            <InputAdornment position="end">
              <i
                className="fa-duotone fa-circle-arrow-right link-submit"
                onClick={handleSubmit}
              ></i>
            </InputAdornment>
          ),
        }}
        onChange={({ target }) => setQuery(target.value)}
      />
    </form>
  );
}

export default SearchProduct;
