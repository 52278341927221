import type { IIndex } from "../types/generic";
import { queryClient } from "./services";

interface IErrorDetails {
  error: string;
  message: null | string;
  path: string;
  requestId: string;
  status: number;
  timestamp: string;
  trace: string;
}

export class HTTPError extends Error {
  status = 0;
  details = {} as IErrorDetails;

  constructor(message: string) {
    super(message);
    this.name = this.constructor.name;
  }
}

export function getGenericErrMsg() {
  const messages = queryClient.getQueryData<IIndex>(["corp_info"]);
  return messages?.genericErrMsg ?? "Something went wrong";
}

export async function httpErrorHandler(response: Response) {
  try {
    const json = await response.json();
    const errorRes = new HTTPError(json.error || getGenericErrMsg());
    errorRes.status = response.status;
    errorRes.details = json;
    return errorRes;
  } catch (e) {
    return createHttpError(e as Error);
  }
}

export function createHttpError(err: Error) {
  const errorRes = new HTTPError(getGenericErrMsg());
  errorRes.details.message = err.message;
  errorRes.stack = err.stack;
  errorRes.cause = err.cause;
  return errorRes;
}
