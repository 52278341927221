import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import ReactMarkdown from "react-markdown";
import ReactMoment from "react-moment";

import { useGetLocales, useLocale } from "../../hooks/useLocale";
import { useGetHomeNotifications } from "../../hooks/useNotification";
import { useGetInterface } from "../../hooks/useInterface";

function TopNav() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [localeName] = useLocale();
  const locales = useGetLocales();
  const notificationItems = useGetHomeNotifications();
  const interfaceItems = useGetInterface();

  function toggleDrawer(ev: React.KeyboardEvent | React.MouseEvent) {
    if (ev.type === "keydown") {
      const _key = (ev as React.KeyboardEvent).key;
      if (_key === "Tab" || _key === "Shift") return;
    }
    setDrawerOpen((open) => !open);
  }

  function handleCloseDrawer() {
    setDrawerOpen(false);
  }

  const renderIconType = (type: string) => {
    return type === "announcement" ? (
      <i className="fa-solid fa-megaphone"></i>
    ) : type === "urgent" ? (
      <i className="fa-sharp fa-solid fa-circle-exclamation"></i>
    ) : type === "shoutout" ? (
      <i className="fa-solid fa-comment-smile"></i>
    ) : type === "news" ? (
      <i className="fa-sharp fa-solid fa-message-lines"></i>
    ) : null;
  };

  const localePreference = locales.data?.find((l) => l.name === localeName);

  if (!notificationItems.data?.length) {
    return null;
  }

  return (
    <>
      <div className="link-container">
        <div className="link-badge" onClick={toggleDrawer}>
          {interfaceItems.data?.urgentNotice}
        </div>
        <span className="badge">
          <i className="fa-sharp fa-solid fa-exclamation"></i>
        </span>
      </div>

      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={handleCloseDrawer}
        id="notice"
      >
        <div role="presentation">
          <span
            id="close"
            className="fa-light fa-xmark"
            onClick={handleCloseDrawer}
          ></span>
          {notificationItems.data?.map((item) => (
            <div key={item.id}>
              {item.content_versions.languages_code !== localeName && (
                <>
                  <div className="lang-alert">
                    <strong>{localePreference?.label}</strong>{" "}
                    {interfaceItems.data?.translationUnavailable}
                  </div>
                  <div className="nudge-sm"></div>
                </>
              )}
              <div className={`notify-${item.type}`}>
                {renderIconType(item.type)}{" "}
                {item.content_versions.friendlyLabel}
              </div>
              <h1>{item.content_versions.heading}</h1>
              <p>
                <strong>
                  <ReactMoment format="D-MMM-YYYY" date={item.date} />
                </strong>
              </p>
              <ReactMarkdown>{item.content_versions.message}</ReactMarkdown>
              <div className="notify-divider"></div>
            </div>
          ))}
        </div>
      </Drawer>
    </>
  );
}

export default TopNav;
