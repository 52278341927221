import { lazy } from "react";
import { createBrowserRouter } from "react-router-dom";

import Root, { RootBoundary } from "../components/Root";

const Translations = lazy(() => import("../pages/translations"));
const Health = lazy(() => import("../pages/health"));
const Home = lazy(() => import("../pages/home"));
const NoMatch = lazy(() => import("../pages/404"));
const Products = lazy(() => import("../pages/products"));
const Support = lazy(() => import("../pages/support"));
const Unsubscribe = lazy(() => import("../pages/unsubscribe"));
// const Storefront = lazy(() => import("../pages/storefront"));
const Partners = lazy(() => import("../pages/partners"));
const Careers = lazy(() => import("../pages/careers"));
const Results = lazy(() => import("../pages/results"));
const Story = lazy(() => import("../pages/story"));
const EQAGlobal = lazy(() => import("../pages/eqa-global"));
const EQAProvider = lazy(() => import("../pages/eqa-providers"));
const IVDManufacturer = lazy(() => import("../pages/ivd-manufacturers"));

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <RootBoundary />,
    children: [
      { index: true, element: <Home /> },
      { path: "/home", element: <Home /> },
      { path: "/404", element: <NoMatch /> },
      { path: "/support", element: <Support /> },
      { path: "/unsubscribe", element: <Unsubscribe /> },
      { path: "/products", element: <Products /> },
      { path: "/eqa-providers", element: <EQAProvider /> },
      { path: "/eqa-global", element: <EQAGlobal /> },
      { path: "/ivd-manufacturers", element: <IVDManufacturer /> },
      { path: "/partners", element: <Partners /> },
      // { path: "/storefront", element: <Storefront /> },
      {
        path: "/translations",
        element: <Translations />,
      },
      { path: "/careers", element: <Careers /> },
      { path: "/meta", element: <Health /> },
      { path: "/results", element: <Results /> },
      { path: "/:param", element: <Story /> },
      { path: "*", element: <NoMatch /> },
    ],
  },
]);

export default router;
