import { useState } from "react";
import { Link } from "react-router-dom";

import { useGetInfo } from "../../hooks/useInfo";
import { cookie } from "../../utils/helpers";

function Cookie() {
  const { data: info } = useGetInfo();

  const [isAccepted, setIsAccepted] = useState(
    !!cookie.get("policy.accepted.cookie")
  );

  function handleAcceptCookie() {
    setIsAccepted(true);

    const expires = new Date();
    expires.setFullYear(expires.getFullYear() + 10, expires.getMonth());
    cookie.set({
      name: "policy.accepted.cookie",
      value: "1",
      options: { expires },
    });
  }

  if (isAccepted) return null;

  return (
    <div className="cookie">
      {info.weuse}{" "}
      <Link to="/cookie-policy" className="link-default">
        {info.cookies}
      </Link>{" "}
      <span className="link-cookie" onClick={handleAcceptCookie}>
        {info.accept}
      </span>
    </div>
  );
}

export default Cookie;
