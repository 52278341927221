import { useQuery } from "@tanstack/react-query";

import type { IIndex } from "../types/generic";
import type { IContentInterface } from "../types/content";
import { useGetLocales, useLocale } from "./useLocale";
import { useGetEnv } from "./useEnv";
import request from "../utils/request";

export function useGetInterface() {
  const env = useGetEnv();
  const [localeName] = useLocale();

  type DataType = Pick<IContentInterface, "key"> & {
    content_versions: {
      translations: Pick<
        IContentInterface["content_versions"][0]["translations"][0],
        "languages_code" | "value"
      >[];
    }[];
  };

  const query = useQuery({
    queryKey: ["interface"],
    queryFn: async () => {
      return await request.make<{ data: DataType[] }>(
        encodeURI(
          `${env?.oasys_url}/api/v1/corp/items/interface` +
            `?fields=key` +
            `,content_versions.translations.languages_code` +
            `,content_versions.translations.value`
        )
      );
    },
    enabled: !!env,
  });

  const localizedData = (() => {
    return query.data?.data?.reduce((result, prop) => {
      const translations = prop.content_versions[0].translations;
      const { value } =
        translations.find((t) => t.languages_code === localeName) ||
        translations.find(
          (t) => t.languages_code === process.env.REACT_APP_DEFAULT_LOCALE
        ) ||
        translations[0] ||
        {};
      return {
        ...result,
        [prop.key]: value,
      };
    }, {} as IIndex);
  })();

  return { ...query, data: localizedData };
}

export function useGetUiTranslations() {
  const env = useGetEnv();
  const { data: locales = [] } = useGetLocales();

  type DataType = Pick<
    IContentInterface,
    "id" | "key" | "no_translations_needed"
  > & {
    content_versions: {
      translations: Pick<
        IContentInterface["content_versions"][0]["translations"][0],
        "languages_code" | "value"
      >[];
    }[];
  };

  const query = useQuery({
    queryKey: ["interface", { uiTranslations: true }],
    queryFn: async () => {
      return await request.make<{ data: DataType[] }>(
        encodeURI(
          `${env?.oasys_url}/api/v1/corp/items/interface` +
            `?fields=id,key,no_translations_needed` +
            `,content_versions.translations.languages_code` +
            `,content_versions.translations.value`
        )
      );
    },
    enabled: !!env,
  });

  const formattedData = (() => {
    return query.data?.data?.map((prop) => ({
      id: prop.id,
      key: prop.key,
      no_translations_needed: prop.no_translations_needed,
      translations: locales.map((lang) => ({
        languageshort: lang.name,
        language: lang.label,
        value: prop.content_versions[0].translations.find(
          (t) => t.languages_code === lang.name
        )?.value,
      })),
    }));
  })();

  return { ...query, data: formattedData };
}
