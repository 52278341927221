import Backdrop from "@mui/material/Backdrop";

import { useGetLocales } from "../../hooks/useLocale";
import { useAuth } from "../../hooks/useAuth";
import LocaleProvider from "../Locale/Context";
import ErrorBoundary from "./ErrorBoundary";
import Layout from "./Layout";

function RootBoundary() {
  return <ErrorBoundary />;
}

function Root() {
  const locales = useGetLocales();
  const auth = useAuth();

  if (locales.isError) {
    throw locales.error;
  }

  return locales.isLoading || auth.isLoading ? (
    <Backdrop open={true}>
      <div className="load" />
    </Backdrop>
  ) : (
    <LocaleProvider>
      <Layout />
    </LocaleProvider>
  );
}

export { Root as default, RootBoundary };
