import { useQuery } from "@tanstack/react-query";

import type { IIndex } from "../types/generic";
import type { IContentInfo } from "../types/content";
import { useLocale } from "./useLocale";
import { useGetEnv } from "./useEnv";
import request from "../utils/request";

export function useGetInfo() {
  const env = useGetEnv();
  const [localeName] = useLocale();

  type DataType = {
    translations: Pick<
      IContentInfo["translations"][0],
      "content" | "languages_code"
    >[];
  };

  const query = useQuery({
    queryKey: ["corp_info"],
    queryFn: async () => {
      return await request.make<{ data: DataType[] }>(
        encodeURI(
          `${env?.oasys_url}/api/v1/corp/items/corp_info` +
            `?fields=translations.content` +
            `,translations.languages_code`
        )
      );
    },
    enabled: !!env,
  });

  const localizedData = (() => {
    const translations = query.data?.data?.[0].translations;
    return ((
      translations?.find((t) => t.languages_code === localeName) ||
      translations?.find(
        (t) => t.languages_code === process.env.REACT_APP_DEFAULT_LOCALE
      ) ||
      translations?.[0]
    )?.content?.[0] || {}) as IIndex;
  })();

  return { ...query, data: localizedData };
}
