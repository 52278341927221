import { useQuery } from "@tanstack/react-query";

import type { IContentStory } from "../types/content";
import { useGetLocales, useLocale } from "./useLocale";
import { useGetEnv } from "./useEnv";
import request from "../utils/request";

export function useGetRouteStory(route?: string) {
  const env = useGetEnv();
  const [localeName] = useLocale();

  type DataType = Pick<
    IContentStory,
    | "id"
    | "route"
    | "cta"
    | "name"
    | "country"
    | "countryCode"
    | "rank"
    | "showCert"
    | "storyType"
    | "city"
    | "latitude"
    | "longitude"
    | "timezone"
    | "status"
    | "backgroundImage"
  > & {
    content_versions: (Pick<
      IContentStory["content_versions"][0],
      "id" | "version"
    > & {
      translations: Pick<
        IContentStory["content_versions"][0]["translations"][0],
        | "id"
        | "menuName"
        | "intro"
        | "headline"
        | "abstract"
        | "content"
        | "contentHTML"
        | "accentHTML"
        | "accentText1"
        | "accentText2"
        | "languages_code"
      >[];
    })[];
  };

  const query = useQuery({
    queryKey: ["story", { route }],
    queryFn: async () => {
      return await request.make<{ data: DataType[] }>(
        encodeURI(
          `${env?.oasys_url}/api/v1/corp/items/stories` +
            `?fields=id` +
            `,route` +
            `,cta` +
            `,name` +
            `,country` +
            `,countryCode` +
            `,rank` +
            `,showCert` +
            `,storyType` +
            `,city` +
            `,latitude` +
            `,longitude` +
            `,timezone` +
            `,status` +
            `,backgroundImage` +
            `,content_versions.id` +
            `,content_versions.version` +
            `,content_versions.translations.id` +
            `,content_versions.translations.menuName` +
            `,content_versions.translations.intro` +
            `,content_versions.translations.headline` +
            `,content_versions.translations.abstract` +
            `,content_versions.translations.content` +
            `,content_versions.translations.contentHTML` +
            `,content_versions.translations.accentHTML` +
            `,content_versions.translations.accentText1` +
            `,content_versions.translations.accentText2` +
            `,content_versions.translations.languages_code` +
            `&filter={"route":"${route}"}` +
            `&limit=1`
        )
      );
    },
    enabled: !!(env && route),
  });

  const localizedData = (() => {
    const { content_versions, ...rest } =
      query.data?.data?.[0] || ({} as IContentStory);
    const { translations = [] } = content_versions?.[0] || {};

    return {
      ...rest,
      content_versions: (() => {
        return (
          translations.find((t) => t.languages_code === localeName) ||
          translations.find(
            (t) => t.languages_code === process.env.REACT_APP_DEFAULT_LOCALE
          ) ||
          translations[0]
        );
      })(),
    };
  })();

  return { ...query, data: localizedData };
}

export function useGetMenuStories() {
  const env = useGetEnv();
  const [localeName] = useLocale();

  type DataType = Pick<IContentStory, "id" | "route"> & {
    content_versions: {
      translations: Pick<
        IContentStory["content_versions"][0]["translations"][0],
        "menuName" | "languages_code"
      >[];
    }[];
  };

  const query = useQuery({
    queryKey: ["stories", { menu: true }],
    queryFn: async () => {
      return await request.make<{ data: DataType[] }>(
        encodeURI(
          `${env?.oasys_url}/api/v1/corp/items/stories` +
            `?fields=id,route` +
            `,content_versions.translations.menuName` +
            `,content_versions.translations.languages_code` +
            `&filter={"showInMenu":true}`
        )
      );
    },
    enabled: !!env,
  });

  const localizedData = (() => {
    return query.data?.data?.map((prop) => ({
      ...prop,
      content_versions: (() => {
        const translations = prop.content_versions[0].translations;
        return (
          translations.find((t) => t.languages_code === localeName) ||
          translations.find(
            (t) => t.languages_code === process.env.REACT_APP_DEFAULT_LOCALE
          ) ||
          translations[0] ||
          {}
        );
      })(),
    }));
  })();

  return { ...query, data: localizedData };
}

export function useGetOfficeStories() {
  const env = useGetEnv();
  const [localeName] = useLocale();

  type DataType = Pick<
    IContentStory,
    | "id"
    | "route"
    | "cta"
    | "name"
    | "showCert"
    | "backgroundImage"
    | "city"
    | "country"
    | "rank"
    | "storyType"
  > & {
    content_versions: (Pick<
      IContentStory["content_versions"][0],
      "id" | "version"
    > & {
      translations: Pick<
        IContentStory["content_versions"][0]["translations"][0],
        | "id"
        | "menuName"
        | "intro"
        | "headline"
        | "abstract"
        | "content"
        | "contentHTML"
        | "accentHTML"
        | "accentText1"
        | "accentText2"
        | "languages_code"
      >[];
    })[];
  };

  const query = useQuery({
    queryKey: ["stories", { storyType: "story" }],
    queryFn: async () => {
      return await request.make<{ data: DataType[] }>(
        encodeURI(
          `${env?.oasys_url}/api/v1/corp/items/stories` +
            `?fields=id,route,cta,name,backgroundImage,city,country, showCert, rank, storyType` +
            `,content_versions.id` +
            `,content_versions.version` +
            `,content_versions.translations.id` +
            `,content_versions.translations.menuName` +
            `,content_versions.translations.intro` +
            `,content_versions.translations.headline` +
            `,content_versions.translations.abstract` +
            `,content_versions.translations.content` +
            `,content_versions.translations.contentHTML` +
            `,content_versions.translations.accentHTML` +
            `,content_versions.translations.accentText1` +
            `,content_versions.translations.accentText2` +
            `,content_versions.translations.languages_code` +
            `&filter={"storyType":"story"}`
        )
      );
    },
    enabled: !!env,
  });

  const localizedData = (() => {
    return query.data?.data?.map((prop) => ({
      ...prop,
      content_versions: (() => {
        const translations = prop.content_versions[0].translations;
        return (
          translations.find((t) => t.languages_code === localeName) ||
          translations.find(
            (t) => t.languages_code === process.env.REACT_APP_DEFAULT_LOCALE
          ) ||
          translations[0] ||
          {}
        );
      })(),
    }));
  })();

  return { ...query, data: localizedData };
}

export function useGetTranslationsStories() {
  const env = useGetEnv();
  const { data: locales = [] } = useGetLocales();

  type DataType = Pick<IContentStory, "id" | "route"> & {
    content_versions: {
      translations: Pick<
        IContentStory["content_versions"][0]["translations"][0],
        "headline" | "languages_code"
      >[];
    }[];
  };

  const query = useQuery({
    queryKey: ["stories", { translations: true }],
    queryFn: async () => {
      return await request.make<{ data: DataType[] }>(
        encodeURI(
          `${env?.oasys_url}/api/v1/corp/items/stories` +
            `?fields=id,route` +
            `,content_versions.translations.headline` +
            `,content_versions.translations.languages_code`
        )
      );
    },
  });

  const formattedData = (() => {
    return query.data?.data?.map((prop) => ({
      id: prop.id,
      route: prop.route,
      translations: locales.map((lang) => ({
        languageshort: lang.name,
        language: lang.label,
        headline: prop.content_versions[0].translations.find(
          (t) => t.languages_code === lang.name
        )?.headline,
      })),
    }));
  })();

  return { ...query, data: formattedData };
}

export function useGetSupportStories() {
  const env = useGetEnv();
  const [localeName] = useLocale();

  type DataType = Pick<IContentStory, "id" | "route" | "backgroundImage"> & {
    content_versions: {
      translations: Pick<
        IContentStory["content_versions"][0]["translations"][0],
        "headline" | "languages_code"
      >[];
    }[];
  };

  const query = useQuery({
    queryKey: ["stories", { support: true }],
    queryFn: async () => {
      return await request.make<{ data: DataType[] }>(
        encodeURI(
          `${env?.oasys_url}/api/v1/corp/items/stories` +
            `?fields=id,route,backgroundImage` +
            `,content_versions.translations.headline` +
            `,content_versions.translations.languages_code` +
            `&filter={"storyType":"support"}`
        )
      );
    },
    enabled: !!env,
  });

  const localizedData = (() => {
    return query.data?.data?.map((prop) => ({
      ...prop,
      content_versions: (() => {
        const translations = prop.content_versions[0].translations;
        return (
          translations.find((t) => t.languages_code === localeName) ||
          translations.find(
            (t) => t.languages_code === process.env.REACT_APP_DEFAULT_LOCALE
          ) ||
          translations[0] ||
          {}
        );
      })(),
    }));
  })();

  return { ...query, data: localizedData };
}

export function useSearchStories(search: string | null) {
  const env = useGetEnv();
  const [localeName] = useLocale();

  type DataType = Pick<IContentStory, "id" | "route" | "status"> & {
    content_versions: {
      translations: Pick<
        IContentStory["content_versions"][0]["translations"][0],
        "id" | "languages_code" | "headline"
      >[];
    }[];
  };

  const query = useQuery({
    queryKey: ["stories", { search }],
    queryFn: async () => {
      return await request.make<{ data: DataType[] }>(
        encodeURI(
          `${env?.oasys_url}/api/v1/corp/items/stories` +
            `?fields=id` +
            `,status` +
            `,route` +
            `,content_versions.translations.id` +
            `,content_versions.translations.headline` +
            `,content_versions.translations.languages_code` +
            `&filter={"_or":` +
            `[{"content_versions":{"translations":{"abstract":{"_contains":"${search}"}}}}` +
            `,{"content_versions":{"translations":{"content":{"_contains":"${search}"}}}}` +
            `,{"content_versions":{"translations":{"headline":{"_contains":"${search}"}}}}` +
            `]}` +
            `&limit=-1`
        )
      );
    },
    enabled: !!(env && search),
  });

  const localizedData = (() => {
    return query.data?.data?.map((prop) => ({
      ...prop,
      content_versions: (() => {
        const translations = prop.content_versions[0].translations;
        return (
          translations.find((t) => t.languages_code === localeName) ||
          translations.find(
            (t) => t.languages_code === process.env.REACT_APP_DEFAULT_LOCALE
          ) ||
          translations[0] ||
          {}
        );
      })(),
    }));
  })();

  return { ...query, data: localizedData };
}
