import { useContext } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";

import type { LocaleType } from "../types/locale";
import type { IContentLanguage } from "../types/content";
import { LocaleContext, SetLocaleContext } from "../components/Locale/Context";
import { useGetEnv } from "./useEnv";
import { useAuth } from "./useAuth";
import { logger } from "../utils/helpers";
import request from "../utils/request";

export function useLocale() {
  const locale = useContext(LocaleContext);
  const setLocale = useContext(SetLocaleContext);

  if (!(locale && setLocale)) {
    throw new Error(
      'useLocale should only be used in "LocaleProvider\'s" context'
    );
  }

  return [locale, setLocale] as const;
}

export function useGetLocales() {
  const env = useGetEnv();

  return useQuery<LocaleType[]>({
    queryKey: ["locales"],
    queryFn: async () => {
      const languages = await request.make<{ data: IContentLanguage[] }>(
        encodeURI(`${env?.oasys_url}/api/v1/corp/items/languages?limit=-1`)
      );

      return (languages.data || []).map((l) => {
        return {
          label: l.title,
          name: l.short,
          enabled: l.enabled,
        } as LocaleType;
      });
    },
    staleTime: Infinity,
    cacheTime: Infinity,
    enabled: !!env,
  });
}

export function useSetAuthLocale() {
  const env = useGetEnv();
  const { data: auth } = useAuth();

  return useMutation({
    mutationFn: async (locale: string) => {
      if (!env?.sso_enabled) return;
      try {
        await request.make(
          `${env?.oasys_url}/api/v1/oasys/users/public/locale/${locale}` +
            `?hmUserId=${auth?.hm_user_id}`,
          {
            method: "PATCH",
          }
        );
      } catch (error) {
        logger(error);
      }
    },
  });
}
