import { QueryClient } from "@tanstack/react-query";
import { ApolloClient, InMemoryCache } from "@apollo/client";
import Keycloak from "keycloak-js";

import type { IEnv } from "../types/env";
import type { IIndex } from "../types/generic";
import { logger } from "./helpers";
import { LOCALSTORE_KEY_AUTH } from "./constants";
import request from "./request";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 30 * 1000,
    },
  },
});

export const keycloak = new Keycloak("/conf/keycloak.json");
keycloak.onAuthSuccess = () => localStorage.setItem(LOCALSTORE_KEY_AUTH, "1");
keycloak.onAuthLogout = () => localStorage.setItem(LOCALSTORE_KEY_AUTH, "0");
keycloak.onTokenExpired = async function () {
  try {
    await keycloak.updateToken(5);
  } catch {
    logout();
  }
};

export const apolloClient = new ApolloClient({
  uri: `https://${process.env.REACT_APP_SHOPIFY_NAME}/api/2021-07/graphql.json`,
  headers: {
    "X-Shopify-Storefront-Access-Token":
      process.env.REACT_APP_SHOPIFY_ACCESS_TOKEN ?? "",
  },
  cache: new InMemoryCache(),
});

export async function getShopifyCheckout(checkoutToken: string) {
  try {
    const url = `https://${process.env.REACT_APP_SHOPIFY_NAME}/api/2021-07/checkouts/${checkoutToken}.json`;
    return await request.make<{ checkout: IIndex }>(url, {
      headers: {
        "X-Shopify-Storefront-Access-Token":
          process.env.REACT_APP_SHOPIFY_ACCESS_TOKEN ?? "",
      },
    });
  } catch (error) {
    logger(error);
  }
}

export async function getEnvData(): Promise<IEnv> {
  return queryClient.fetchQuery({
    queryKey: ["conf/env"],
    queryFn: async () => {
      const url = `${process.env.PUBLIC_URL}/conf/env.json`;
      return await request.make<IEnv>(url);
    },
    staleTime: Infinity,
    cacheTime: Infinity,
  });
}

export async function logout(redirectUri?: string) {
  try {
    await request.call("/logout", { method: "POST" });
  } catch {
  } finally {
    keycloak.logout({ redirectUri });
  }
}
