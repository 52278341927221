import Tooltip from "@mui/material/Tooltip";

import type { LocaleType } from "../../types/locale";
import { useGetLocales, useLocale } from "../../hooks/useLocale";

function LocaleSelector() {
  const locales = useGetLocales();
  const [localeName, setLocaleName] = useLocale();

  function handleClick(name: LocaleType["name"]) {
    setLocaleName(name);
  }

  return (
    <div className="lang">
      <ul>
        {locales.data?.map(
          ({ name, label, enabled }) =>
            enabled && (
              <li key={name}>
                <Tooltip
                  classes={{ tooltip: "tooltip", arrow: "tooltip-arrow" }}
                  placement="bottom"
                  title={label}
                  arrow
                >
                  <span
                    className={
                      name === localeName ? "selected pulse-fast" : "link-inert"
                    }
                    onClick={() => {
                      handleClick(name);
                    }}
                  >
                    {name.toUpperCase()}
                  </span>
                </Tooltip>
              </li>
            )
        )}
      </ul>
    </div>
  );
}

export default LocaleSelector;
