import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { QueryClientProvider } from "@tanstack/react-query";

import "./index.scss";
import { getEnvData, queryClient } from "./utils/services";
import router from "./utils/router";
import reportWebVitals from "./reportWebVitals";

(async () => {
  const { env } = await getEnvData();

  import("react-gtm-module").then(({ default: TagManager }) => {
    if (env === "prod") {
      TagManager.initialize({ gtmId: "GTM-NRHWGFC" });
    }
  });
})();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <QueryClientProvider client={queryClient}>
    <RouterProvider router={router} />
  </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
