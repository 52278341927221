import type { PropsWithChildren } from "react";
import { createContext, useCallback, useEffect, useState } from "react";

import type { LocaleType } from "../../types/locale";
import { LOCALSTORE_KEY_LOCALE } from "../../utils/constants";
import { useGetLocales, useSetAuthLocale } from "../../hooks/useLocale";
import { useAuth } from "../../hooks/useAuth";

type LocaleProviderType = PropsWithChildren<{}>;

const LocaleContext = createContext<LocaleType["name"] | null>(null);
const SetLocaleContext = createContext<((locale: string) => void) | null>(null);

function LocaleProvider({ children }: LocaleProviderType) {
  const { data: user } = useAuth();
  const { data: locales = [] } = useGetLocales();
  const { mutate: setAuthLocale } = useSetAuthLocale();
  const [localeName, setLocaleName] = useState(getInitLocaleName());
  const localeStr = locales.reduce((r, { name }) => (r += `${name},`), "");

  const setLocale = useCallback(
    (name: string, sync = true) => {
      if (!name || !localeStr.includes(name)) return;
      const locale = name as LocaleType["name"];
      localStorage.setItem(LOCALSTORE_KEY_LOCALE, locale);
      setLocaleName(locale);
      if (sync) setAuthLocale(locale);
    },
    [localeStr, setAuthLocale]
  );

  function getInitLocaleName() {
    const cache = user?.locale || localStorage.getItem(LOCALSTORE_KEY_LOCALE);
    return (locales.find((l) => l.name === cache)?.name ||
      process.env.REACT_APP_DEFAULT_LOCALE) as LocaleType["name"];
  }

  useEffect(() => {
    if (user?.locale) setLocale(user.locale, false);
  }, [setLocale, user?.locale]);

  return (
    <SetLocaleContext.Provider value={setLocale}>
      <LocaleContext.Provider value={localeName}>
        {children}
      </LocaleContext.Provider>
    </SetLocaleContext.Provider>
  );
}

export { LocaleProvider as default, LocaleContext, SetLocaleContext };
