import { Suspense, useEffect } from "react";
import { ApolloProvider } from "@apollo/client";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Outlet, useLocation } from "react-router-dom";

import { apolloClient } from "../../utils/services";
import NoticeProvider from "../Notice";
import Cookie from "../Cookie";
import Footer from "../Footer";
import Header from "../Header";

function Layout() {
  const { pathname } = useLocation();

  const hideFooter = [
    "/products",
    "/meta",
    "/storefront",
    "/translations",
    "/careers",
  ].includes(pathname);

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: "auto" });
  }, [pathname]);

  return (
    <HelmetProvider>
      <Helmet>
        <title>Oneworld Accuracy</title>
      </Helmet>

      <div className="contain fade-in-step-1">
        <ApolloProvider client={apolloClient}>
          <Header />
          <NoticeProvider>
            <Suspense fallback={<div />}>
              <Outlet />
            </Suspense>
          </NoticeProvider>
          {!hideFooter && <Footer key={pathname} />}
          <Cookie />
        </ApolloProvider>
      </div>
    </HelmetProvider>
  );
}

export default Layout;
