import LocaleSelector from "../Locale";
import TopNav from "./TopNav";

function Header() {
  return (
    <header>
      <div className="desktop">
        <LocaleSelector />
      </div>
      <TopNav />
    </header>
  );
}

export default Header;
