import { useEffect, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";

import type { IEnv } from "../types/env";
import { getEnvData } from "../utils/services";

export function useGetEnv() {
  const queryClient = useQueryClient();
  const [data, setData] = useState<IEnv | undefined>(
    queryClient.getQueryData(["conf/env"])
  );

  useEffect(() => {
    void (async function () {
      setData(await getEnvData());
    })();
  }, []);

  return data;
}
